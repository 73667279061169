<script lang="ts" setup>
import dayjs from "dayjs";

const emit = defineEmits<{ (event: "scrollToContent"): void; (event: "updateQuestData"): void }>();

const props = defineProps<{
	isHomePage?: boolean;
}>();

const { t } = useT();
const { open } = useTaoModals();
const prepareImgUrl = useImage();
const { data: questsData, totalTasksPrize, refresh } = useGetPageQuestData({ immediate: false });

const endTime = computed(() =>
	dayjs(questsData.value?.quest?.end).diff(dayjs(), "second") < 0 ? "" : questsData.value?.quest?.end || ""
);

const { durationLeft, duration, reset } = useCountdown({
	timestamp: endTime.value,
	formatToken: "D[d] HH[h] mm[m] ss[s]",
	onCountdownStop: () => {
		emit("updateQuestData");
	}
});

watch(
	() => questsData.value?.quest?.end,
	(value) => {
		reset(value ?? "");
	}
);

onMounted(() => {
	if (props.isHomePage) {
		refresh();
	}
});
</script>
<template>
	<ABanner
		class="quest-banner"
		background="var(--gaalkacyo);"
		:sources="[
			{
				srcset: prepareImgUrl('/nuxt-img/quest/quest-banner.webp', {
					format: 'avif',
					loading: 'lazy',
					width: 660,
					height: 628
				}),
				width: 330,
				height: 348,
				media: '(max-width: 575px)'
			}
		]"
		:img-attrs="{
			alt: 'banner',
			src: '/nuxt-img/quest/quest-banner@2x.jpg',
			format: 'avif',
			loading: 'lazy'
		}"
	>
		<div class="banner-content">
			<AText v-if="duration >= 0" variant="topeka" class="deadline">
				<i18n-t keypath="Time left {key}">
					<template #key>
						<b>{{ durationLeft.replace(/0d|00h/gi, "") }}</b>
					</template>
				</i18n-t>
			</AText>
			<div class="content">
				<AText variant="tangier" :modifiers="['uppercase', 'semibold']" class="banner-text text-coro" as="div">
					{{ t("Complete missions every day and get") }}
					<AText variant="tangier" :modifiers="['uppercase', 'semibold']" class="banner-text subtitle">
						{{ t("rewards to collect") }}
					</AText>
				</AText>
				<div class="banner-prize">
					<AText
						v-if="totalTasksPrize.totalEntries"
						:size="{ full: 36, lg: 28 }"
						:modifiers="['bold', 'uppercase']"
						class="prize-fund"
					>
						<ASvg name="12/secret-coins" class="icon" />
						{{ numberFormat(totalTasksPrize.totalEntries) }}
					</AText>
					<AText
						v-if="totalTasksPrize.totalCoins"
						:size="{ full: 36, lg: 28 }"
						:modifiers="['bold', 'uppercase']"
						class="prize-fund"
					>
						<ASvg name="12/coins" class="icon" />
						{{ numberFormat(totalTasksPrize.totalCoins) }}
					</AText>
				</div>
			</div>
			<div class="banner-actions">
				<AText variant="toledo" :modifiers="['link', 'underline']" @click="open('LazyOModalQuestHowItWorks')">
					{{ t("How it works") }}
				</AText>
			</div>
		</div>
	</ABanner>
</template>
<style scoped lang="scss">
.quest-banner {
	height: 257px;

	&:deep(picture) {
		left: 0;
		top: 0;
	}

	&:deep(.content) {
		max-width: 621px;
	}

	@include media-breakpoint-down(md) {
		border-radius: 10px;

		&:deep(picture) {
			--a-banner-default-image-width: 100%;
			--a-banner-default-picture-bottom-position: 0;
			right: 0;
			transform: none;
		}
	}

	@include media-breakpoint-down(sm) {
		height: 348px;

		&:deep(picture) {
			--a-banner-default-image-height: 100%;
			bottom: 0;
		}
	}

	h3 {
		text-shadow: 0 2px 1px var(--coronel);

		br:last-of-type {
			display: none;
		}

		@include media-breakpoint-down(sm) {
			text-align: center;

			br:first-of-type {
				display: none;
			}
			br:last-of-type {
				display: inline;
			}
		}
	}
}
.banner-content {
	position: absolute;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px 56px;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;

	@include media-breakpoint-down(sm) {
		justify-content: start;
		padding: 20px 14px;
		align-items: center;
		gap: 5px;
	}

	.deadline {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		background: var(--overlay-100);
		padding: 2px 4px;

		&:deep(b) {
			padding-left: 5px;
		}
	}

	.content {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-down(sm) {
			align-items: center;

			& > * {
				text-align: center;
			}
		}
	}

	.banner-text {
		line-height: 32px;

		&.subtitle {
			color: var(--alternative-green);
		}
	}
}

.banner-prize {
	color: var(--coro);
	display: flex;
	align-items: center;
	gap: gutter(2);
	margin-top: 20px;

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		gap: gutter(0.625);
		margin: 5px 0;
	}
}

.banner-actions {
	display: flex;
	align-items: center;
	gap: gutter(5);

	button {
		&:not(&.large) {
			width: 240px;
		}
	}

	.link {
		color: var(--coro);
	}

	@include media-breakpoint-down(sm) {
		flex-direction: column;
		gap: gutter(1.25);
		max-width: 100%;

		button {
			width: 270px;
			max-width: 100%;
		}
	}
}

.prize-fund {
	display: inline-flex;
	align-items: center;
	gap: gutter(1);

	--m-fund-entries-color: var(--coro);
	--m-fund-coins-color: var(--coro);

	.icon {
		font-size: 40px;
	}
}
</style>
